import React from "react"
import PropTypes from "prop-types"

const PostTags = ({ posts }) => {
	const renderPostTags = (posts) => {
		const allTags = []
		const uniqueTags = []
		const taglist = []
		posts.forEach((e) => {
			const slug = e.slug
			const name = e.name

			// Check if the post is defined and has the required properties
			if (!e || !slug || !name) {
				console.warn("Post is missing required properties:", e)
				return
			}
			if (uniqueTags.indexOf(slug) === -1) {
				// get unique objects only
				if (name.indexOf(`Blog.`) > -1) {
					allTags.push({
						key: slug,
						count: 1,
						parent: name.replace(`Blog.`, ``).split(`.`)[0],
						child1: name.replace(`Blog.`, ``).split(`.`)[1],
						slug: slug,
					})
					uniqueTags.push(slug)
				}
			} else {
				// increment counts if not unique
				for (const tag of allTags) {
					if (tag.key === e.slug) {
						tag.count += 1
					}
				}
			}
		})

		const sortedAllTags = allTags.slice().sort((a, b) =>
			a.child1 > b.child1 ? 1 : -1
		)
		const allParents = allTags.slice().sort((a, b) =>
			a.count < b.count ? 1 : -1
		)

		let u = []
		let ky = 0
		for (const parent of allParents) {
			if (u.indexOf(parent.parent) === -1) {
				taglist.push(
					<h5 key={parent.parent} title={parent.parent}>
						{parent.parent}
					</h5>
				)
				for (const tag of sortedAllTags) {
					if (tag.parent === parent.parent) {
						taglist.push(
							<span className="post-tag__link" key={tag.child1 + tag.count}>
								<a href={`/tag/` + tag.slug} title={tag.child1}>
									{tag.child1}
								</a>
								<sup>{tag.count}</sup>
							</span>
						)
					}
				}
				ky = ky + 1
				taglist.push(<br key={ky} />)
			}
			u.push(parent.parent)
		}
		return taglist
	}

	return <>{renderPostTags(posts)}</>
}

PostTags.propTypes = {
	posts: PropTypes.array.isRequired,
}

export default PostTags
