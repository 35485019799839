import React from 'react'
import PropTypes from 'prop-types'

// highlight text in title
function getTitle(query, title) {

    if (query.length > 2) {
        // remove wildcard or illegal characters from beginning of string, remove stop words from string, and remove spaces from end of string
        const queryThatsClean = query.replace(/[*-+~]/gi,``).replace(/(AND\s|OR\s)/gi,``).trim()

        // split the query into an array
        const queryArray = queryThatsClean.split(` `)

        // turn the array into a regular expression of "or" clauses surrounded by parens for returning words in the clause
        const queryRegex = new RegExp("(" + queryArray.join("|") + ")","gi");

        // split the text string into individual words including the word found in the split argument
        const titleStringFragments = title.split(queryRegex)
    
        // reassemble the words back into an array, and if the word contains one of the query terms then wrap it with HTML 
        const returnableArray = titleStringFragments.map((word, index) =>          
            queryRegex.test(word) ? <span className="search-results__query-term-found" key={`${word}-${index}`}>{word}</span> : word
        )

        return returnableArray

    } else {

        return title
    }
}

// Split text on query term, include term itself into parts, ignore case
function getHighlightedText(query, text) {
    if (query && text) {
        const parts = text.split(new RegExp(`(${query})`, `gi`))
        return <span>{parts.map((part, index) => (
            part.toLowerCase() === query.toLowerCase() 
                ? <span className="search-results__query-term-found" key={`${part}-${index}-${text.length}`}>{part}</span> 
                : part
        ))}</span>
    }
    return null
}

function getHTML(query, html) {

    // remove all stop words and all wildcard or illegal characters from beginning of string
    const queryThatsClean = query
        .replace(/(AND\s|OR\s)/gi,``)
        .replace(/^[*-+~]/,``)

    // split the query into an array
    const queryArray = queryThatsClean.split(` `)

    let returnableArray = []
    queryArray.forEach((q) => {

        // remove all wildcards
        q = q.replace(/[*-+~]/gi,``)

        // turn specific html tags into array
        const htmlCleaned= html ? html
            .replace(/<a(.*)>/gi,``)
            .replace(/<img.*>/gi,``)
            .replace(/(<([^>]+)>)/gi,``)
            .match(/(.*)/gi)
            : ``

        // remove unwanted characters from tags 
        let htmlStripped = []
        if (!!htmlCleaned&& htmlCleaned!== ``) {
            htmlCleaned.forEach((e) => {
                let tmp = e
                    .replace(/[:.;*]/gi,``)
                    .replace(/&quot/gi,``)
                    .replace(/&amp/gi,``)
                    .replace(/[“”"]/gi,``)
                    .trim()

                if (tmp.length > 0) {
                    htmlStripped.push(tmp)
                }
            })
        }

        // join tags together then split on each word
        let wordInHTML = htmlStripped.join(` `).split(` `)

        // find the index of words in html that include query term
        let queryFoundAtIndex = wordInHTML.reduce(function (a, e, i) {
            if (e.toLowerCase().includes(q.toLowerCase())) { a.push(i) }
            return a
        }, [])

        // create snippet of html content containing search term
        let snipHTML = [], snipCount = 0
        if (queryFoundAtIndex) {
            queryFoundAtIndex.forEach((e) => {
                let tmp = []

                // wrap query string with adjacent words in HTML
                for (let i = e - 3; i < (e + 4); i++) {
                    if (wordInHTML[i]) {
                        tmp.push(wordInHTML[i].replace(/[.,:;"']/gi,``))
                    }
                }

                // return only three hits
                if (snipCount < 2) {
                    // and ellipses and spaces to each snip
                    snipHTML.push(`... ` + tmp.join(` `) + ` `)
                    snipCount ++
                }
            })
        }

        // join snip into extract
        let extract = snipHTML.join(` `)
        returnableArray.push(getHighlightedText(q,extract))
    })
    return returnableArray
}

const SearchResultsList = ({ query, results, location }) => {

    // create lists only with results that have tags
    const allNonEmptyResults = results.reduce(function(result, id) {
        if (id.primary_tag) {
            result.push(id);
        }
            return result;
        }, []
    );
    const allNonEmptyTags = results.flatMap(({ primary_tag }) => primary_tag);
    const primaryTags = []
    const uniqueTags = []

    // get list of unique tags
    allNonEmptyTags.forEach((e) => {
        let name = e.name.replace(`Blog.`,``).split(`.`)[0]
        if (uniqueTags.indexOf(name) === -1) {
            primaryTags.push({
                key: e.id,
                name: name,
                slug: e.slug.split('-')[0]
            })
            uniqueTags.push(name)
        }
    })

    return (
        <section className="search-results" aria-label="Search results for all posts">
            {!!results.length && query && 
                <span className="search-results__count" aria-live="assertive">Found <strong>{results.length}</strong> posts matching "<strong>{query}</strong>"</span>
            }

            {!!results.length && !!query &&
                <ol className="search-results__tag-group-list">
                    {primaryTags.map(({
                        key,
                        name,
                        slug
                    }) => (
                        <li key={key.toString()} className="search-results__tag-group">
                            <img src={"/" + slug.toLowerCase() + ".png"} alt={name} />
                            <h5>{name}</h5>
                            <ol className="search-results__tag-list">
                                {allNonEmptyResults.map(({
                                    excerpt,
                                    html,
                                    primary_tag,
                                    published_at,
                                    title,
                                    url,
                                    uuid,
                                }) => {
                                    if (name === primary_tag.name.replace(`Blog.`,``).split(`.`)[0]) {
                                        return (
                                            <li key={uuid.toString()} className="search-results__tag">
                                                <p className="search-results__heading">
                                                    <a href={url.replace(`https://ghost.maxderungs.com`,location)}>
                                                        {getTitle(query,title)}
                                                    </a>
                                                </p>

                                                {html && <p className="search-results__extract">{getHTML(query,html)}</p>}

                                                <p className="search-results__date">{(new Date(published_at).toLocaleString(`en-GB`, { year: `numeric` }))}</p>
                                            </li>
                                        )
                                    } else {
                                        return null;
                                    }
                                })}
                            </ol>
                        </li>
                    ))}
                </ol>
            }
        </section>
    )
}

SearchResultsList.propTypes = {
    query: PropTypes.string.isRequired,
    results: PropTypes.arrayOf(PropTypes.shape({
        excerpt: PropTypes.string,
        html: PropTypes.string,
        primary_tag: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            slug: PropTypes.string
        }),
        published_at: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
        uuid: PropTypes.string
    })).isRequired,
    location: PropTypes.string.isRequired
}

export default SearchResultsList