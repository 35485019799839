import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Layout, PostCard } from "../components/common"
import { MetaData } from "../components/common/meta"
import SearchForm from "../components/common/SearchForm"
import SearchResultsList from "../components/common/SearchResultsList"
import PostTags from "../components/common/PostTags"

const Index = ({ data, location }) => {
	const posts = data.allGhostPost.edges
	const postValues = Object.values(posts)
	const allNodes = []
	const allTagsArray = []

	const allPostNodes = []
	const allPosts = []

	const allFeaturedPostNodes = []
	const allFeaturedPosts = []

	const featuredItemLimit = 3
	const blogPostItemLimit = 1000

	// get all the nodes
	for (const node of postValues) {
		allNodes.push(node)
		allPostNodes.push(node)
		allFeaturedPostNodes.push(node)
	}

	// get all the tag object arrays into a single object array
	for (let i = 0, leni = allNodes.length; i < leni; i++) {
		for (const tagobj of allNodes[i].node.tags) {
			allTagsArray.push(tagobj)
		}
	}

	// get a specific number of Featured posts
	let featuredPostCount = 0
	for (let k = 0, lenk = allFeaturedPostNodes.length; k < lenk; k++) {
		if (
			allFeaturedPostNodes[k].node.featured === true &&
			featuredPostCount < featuredItemLimit
		) {
			allFeaturedPosts.push({ node: allFeaturedPostNodes[k].node })
			featuredPostCount = featuredPostCount + 1
		}
	}

	// get a specific number of NON-Featured posts
	let postCount = 0
	for (let j = 0, lenj = allPostNodes.length; j < lenj; j++) {
		if (
			allPostNodes[j].node.featured === false &&
			postCount < blogPostItemLimit
		) {
			allPosts.push({ node: allPostNodes[j].node })
			postCount = postCount + 1
		}
	}

	// search result fields come from gatsby-config.js
	const [results, setResults] = useState([])
	const searchQuery = new URLSearchParams(location.search).get(`q`) || ``

	useEffect(() => {
		const searchQuery = new URLSearchParams(location.search).get(`q`) || ``
		if (searchQuery && window.__LUNR__) {
			window.__LUNR__.__loaded.then((lunr) => {
				const refs = lunr.en.index.search(searchQuery.replace(/"/, ``))
				const posts = refs.map(({ ref }) => lunr.en.store[ref])
				setResults(posts)
			})
		}
	}, [location.search])

	return (
		<>
			<MetaData location={location} />
			<Layout isHome={true}>
				{/* Searchbox */}
				<div className="container search">
					<SearchForm query={searchQuery} />
					<SearchResultsList
						query={searchQuery}
						results={results}
						location={[location.origin].toString()}
					/>
				</div>

				{/* Tags */}
				<div className="container">
					{posts && (
						<section className="post-tag">
							<PostTags posts={allTagsArray} />
						</section>
					)}
				</div>

				{/* Featured Posts */}
				<div className="container">
					<h2>Featured Posts</h2>
					<section className="tag-feed">
						{allFeaturedPosts.map(({ node }) => (
							<PostCard key={node.id} post={node} />
						))}
					</section>
				</div>

				{/* Posts */}
				<div className="container">
					<h2>Recent Posts</h2>
					<section className="tag-feed">
						{/* Remove matching post from feed */}
						{allPosts.map(({ node }) => {
							if (!node.slug.startsWith("_")) {
								return <PostCard key={node.id} post={node} />
							} else {
								return null
							}
						})}
					</section>
				</div>

				<div className="container">
					<br />
				</div>
			</Layout>
		</>
	)
}

Index.propTypes = {
	data: PropTypes.shape({
		allGhostPost: PropTypes.object.isRequired,
		allGhostTag: PropTypes.object.isRequired,
	}).isRequired,
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired,
		origin: PropTypes.string.isRequired,
		search: PropTypes.string.isRequired,
	}).isRequired,
}

export default Index

// This page query loads all posts sorted descending by published date
// Return all posts except - for the Gatsby default "data-schema" card
export const pageQuery = graphql`
	query GhostPostQuery($limit: Int!, $skip: Int!) {
		allGhostPost(
			filter: { slug: { ne: "data-schema" } }
			sort: { fields: [published_at], order: DESC }
			limit: $limit
			skip: $skip
		) {
			edges {
				node {
					...GhostPostFields
				}
			}
		}
		allGhostTag(
			filter: { slug: { ne: "data-schema" } }
			sort: { fields: [postCount], order: DESC }
		) {
			edges {
				node {
					id
					slug
					name
					postCount
					visibility
				}
			}
		}
	}
`
